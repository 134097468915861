import React from "react";
import {Box, Text} from '@chakra-ui/react';
import {ProductCostCalculateUtils} from "../../../shared/utilities/ProductCostCalculateUtils";
import {ContentTypes, Product} from "../../../shared/entities/Product/Product";
import {Dealership} from "../../../shared/entities/Users/Dealership";


interface DiscountComponentProps {
    elem: Product;
    hiddenUOM?: boolean;
    history?: boolean;
    selectedDealer: Dealership | undefined;
    textAlign?: string;
    showMarkup?: boolean;
    showTax?: boolean;
    showDiscount?: boolean;
    fontSizePrice?: any
    fontWeightPrice?: any
    cart?: boolean;
}

const DiscountComponent: React.FC<DiscountComponentProps> = ({
                                                                 elem,
                                                                 hiddenUOM = false,
                                                                 selectedDealer,
                                                                 textAlign = "center",
                                                                 history = false,
                                                                 showDiscount = false,
                                                                 showMarkup = false,
                                                                 showTax = false,
                                                                 fontSizePrice = "16px",
                                                                 fontWeightPrice = "md",
                                                                 cart = false
                                                             }) => {

    const calculatedPrice = history ? (elem.finalPrice !== undefined ? elem.finalPrice : 0) : ProductCostCalculateUtils.calculatePrice(elem, selectedDealer);
    let discountPrice;
    let markupPrice;
    if (history) {
        if (elem.discount !== undefined)
            if (elem.discount > 0) {
                markupPrice = elem.discount
            } else if (elem.discount < 0) {
                discountPrice = elem.discount * -1
            }
    } else {
        discountPrice = history ? (elem.discount !== undefined && elem.discount < 0 ? elem.discount * -1 : 0) : ProductCostCalculateUtils.getPrice(elem, selectedDealer, ContentTypes.DISCOUNT_DEALER);
        markupPrice = history ? (elem.discount !== undefined && elem.discount > 0 ? elem.discount : 0) : ProductCostCalculateUtils.getPrice(elem, selectedDealer, ContentTypes.MARKUP_DEALER);
    }
    const taxPrice = history ? (elem.taxCost !== undefined ? elem.taxCost : 0) : ProductCostCalculateUtils.getPrice(elem, selectedDealer, ContentTypes.TAX_DEALER);
    return (
        <Box display="flex" flexDir="column" textAlign={textAlign === "center" ? "center" : "left"} minWidth={"100px"}>
            {(discountPrice !== undefined && showDiscount) && (
                <Text fontSize="14px" color={"gray.400"}>
                    <span>{"$" + elem.price.toFixed(2)}</span>
                    <span style={{color: "gray.400", marginLeft: "5px"}}>{"(-" + discountPrice.toFixed(2) + "%)"}</span>
                </Text>
            )}
            {(markupPrice !== undefined && showMarkup) && (
                <Text fontSize="14px" color={"gray.400"}>
                    <span>{"$" + elem.price.toFixed(2)}</span>
                    <span style={{color: "gray.400", marginLeft: "5px"}}>{"(+" + markupPrice.toFixed(2) + "%)"}</span>
                </Text>
            )}
            {(taxPrice !== undefined && showTax) && (
                <Text fontSize="14px" color={"gray.400"}>
                    <span style={{color: "red"}}>{"(tax " + taxPrice.toFixed(2) + "%)"}</span>
                </Text>
            )}
            <Text fontSize="14px" fontWeight={"md"}>
                {cart &&
                    <span>{`${elem.quantity} / `}</span>
                }
                <span style={{fontSize: fontSizePrice}}>
                    {"$" + (!hiddenUOM ? calculatedPrice : calculatedPrice.toFixed(2))}
                </span>
                <span style={{
                    fontWeight: fontWeightPrice,
                    paddingLeft: "8px"
                }}>{(!hiddenUOM ? " " + elem.unitOfMeasurement.toLowerCase() : "")}</span>
            </Text>
        </Box>
    );
};

export default DiscountComponent;