import {PayloadAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {Option} from "../shared/utilities/OptionT";
import {ProductService} from "../service/Product/ProductService";
import {
    ProductChangeOrderBy,
    ProductChangeParent,
    ProductChangeType,
    ProductRequest,
    ProductSetArchive
} from "../shared/entities/Product/Product";
import {ActionTypes} from "../redux/Order/OrderReducer";

export class ProductController {
    private dispatch: Dispatch<PayloadAction<any>>
    private service: ProductService

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
        this.service = new ProductService(this.dispatch)
    }

    public async getProductList() {
        return this.service.getProductList().then(res => {
            return ProductController.handleResult(res)
        })
    }

    public async getGeneralMenu(id: number) {
        return this.service.getGeneralMenu(id).then(res => {
            return res
        })
    }

    public async getProducts(params: {}) {
        return this.service.getProducts(params).then(res => {
            return res
        })
    }

    public async getProductsIds(params: {}) {
        return this.service.getProductsIds(params).then(res => {
            return res
        })
    }

    public async getProduct(id: number) {
        return this.service.getProduct(id).then(res => {
            return ProductController.handleResult(res)
        })
    }

    public async createProduct(data: ProductRequest) {
        return this.service.createProduct(data).then(res => res)
    }

    public async changeTypeProduct(data: ProductChangeType) {
        return this.service.changeTypeProduct(data).then(res => res)
    }

    public async changeParentProduct(data: ProductChangeParent) {
        return this.service.changeParentProduct(data).then(res => res)
    }

    public async changeOrderByProduct(data: ProductChangeOrderBy) {
        return this.service.changeOrderByProduct(data).then(res => res)
    }

    public async setArchiveProduct(data: ProductSetArchive) {
        return this.service.setArchiveProduct(data).then(res => res)
    }

    public async deleteProduct(id: number) {
        return this.service.deleteProduct(id)
    }

    private static handleResult<T>(res: Option<T>): Option<boolean> {
        if (res?.None) {
            return {
                Some: false,
                None: res.None
            }
        }
        return {Some: true}
    }
}
