import {Product} from "../../shared/entities/Product/Product";
import {ProductTypes} from "../../common/constants";

export class ProductListManager {
    static getRowsProducts(productList: Product[], maxCount: number | undefined, allowedTypes: ProductTypes[]): Product[][] {
        const filteredProductList = productList.filter(
            (elem) => allowedTypes.includes(elem.type)
        );
        const rows: Product[][] = [];
        if (maxCount) {
            for (let i = 0; i < filteredProductList.length; i += maxCount) {
                rows.push(filteredProductList.slice(i, i + maxCount));
            }
        }
        return rows;
    }
}
