import React from 'react';
import {Flex, Text} from '@chakra-ui/react';
import {Product} from '../../../shared/entities/Product/Product';
import {URLPaths} from "../../../config/application/URLPaths";
import {usePrivileges} from "../../../hooks/PrivilegesProvider";
import EditButtons from "../../../components/Buttons/EditButtons";
import ImageCustom from "../../../components/Image/ImageCustom";
import DragAndDropBox from "../../../components/DragAndDrop/DragAndDropBox";
import {useNavigate} from "react-router";
import {CardBody} from "../../../components/Card/CardBody";

interface ProductItemProps {
    product: Product;
    height?: string;
    width?: string;
    divider: number;
    isMobile?: boolean;
}

const ProductGeneralItem: React.FC<ProductItemProps> = ({
                                                            product,
                                                            height,
                                                            width = "100%",
                                                            divider = 1,
                                                            isMobile = false
                                                        }) => {
    const {priv} = usePrivileges()
    const navigate = useNavigate();

    return (
        <Flex w={width} minHeight={height}>
            {priv?.PRODUCT_EDIT &&
                <DragAndDropBox item={product} width={"18px"} orderByZone={true} height={height}/>
            }
            <CardBody>
                <DragAndDropBox item={product} isDraggable={priv?.PRODUCT_EDIT}>
                    <EditButtons item={product}/>
                    <ImageCustom elem={product} currentSize={"subgeneral"} sizeBackend={"GENERAL"} isMobail={isMobile}
                                 sizeInPercent={100 / divider}/>
                    <Flex px={6} cursor={"pointer"}
                          alignItems="center"
                          justifyContent="flex-start"
                          width="100%" height={24} onClick={() => navigate(`${URLPaths.PRODUCTS.link}${product.id}`)}>
                        <Text fontWeight={"md"} fontSize={22} lineHeight={"115%"}>
                            {product.name}
                        </Text>
                    </Flex>
                </DragAndDropBox>
            </CardBody>
        </Flex>
    );
};

export default ProductGeneralItem;
