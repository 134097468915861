import {PayloadAction, Reducer} from "@reduxjs/toolkit"
import {Order} from "../../shared/entities/Order/Order";
import {SendMailResponse} from "../../shared/Mail/SendMailResponse";

export type OrderState = {
    orderList: Array<Order> | []
    order: Order | null
    invoiceFile: Blob
    orderCheckout?: Order
    sendMailResponse: SendMailResponse | null
    orderClientList: { orders: Array<Order>, count: number }
}

const State: OrderState = {
    orderList: [],
    order: null,
    sendMailResponse: null,
    invoiceFile: new Blob(),
    orderCheckout: undefined,
    orderClientList: {
        orders: [],
        count: 0
    }
}

export enum ActionTypes {
    ORDER = "ORDER",
    INVOICE_PDF = "INVOICE_PDF",
    ORDER_LIST = "ORDER_LIST",
    ORDER_CHECKOUT = "ORDER_CHECKOUT",
    GET_ORDER_LIST = "GET_ORDER_LIST",
    SEND_INVOICE = "SEND_INVOICE",
    PRINT_PRESSED = "PRINT_PRESSED",
}

export const OrderReducer: Reducer<OrderState, PayloadAction<any>> = (state = State, action: PayloadAction<any>): OrderState => {
    switch (action.type) {
        case ActionTypes.ORDER:
            return state = {
                ...state,
                order: action.payload,
            }
        case ActionTypes.ORDER_LIST:
            return state = {
                ...state,
                orderList: action.payload,
            }
        case ActionTypes.GET_ORDER_LIST:
            return state = {
                ...state,
                orderClientList: action.payload
            }
        case ActionTypes.ORDER_CHECKOUT:
            return state = {
                ...state,
                orderCheckout: action.payload
            }
        case ActionTypes.INVOICE_PDF:
            return state = {
                ...state,
                invoiceFile: action.payload,
            }
        case ActionTypes.SEND_INVOICE:
            return state = {
                ...state,
                sendMailResponse: action.payload,
            }
        default:
            return state
    }
}

