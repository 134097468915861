import {PayloadAction} from "@reduxjs/toolkit";
import {Reducer} from "react";
import {CustomModalConfig} from "../../shared/entities/Modal/ModalConfig";

export type ModalState = {
    data: CustomModalConfig | null
    event: any
}

const State: ModalState = {
    data: null,
    event: null
}

export enum ModalTypes {
    MODAL_ADD = "MODAL_ADD",
    MODAL_DELETE = "MODAL_DELETE",
    OPEN_MODAL = "OPEN_MODAL",
    RESET_EVENT = "RESET_EVENT"
}

export enum SelectModal {
    MINI_CART = 'MINI_CART',
    DISCOUNT = 'DISCOUNT',
    SET_PASSWORD = 'SET_PASSWORD',
    ADD_DEALERSHIP = 'ADD_DEALERSHIP',
    ADD_PRODUCT = 'ADD_PRODUCT',
    ADD_USER = 'ADD_USER',
    BASKET = 'MENU_BASKET',
    MENU = 'MENU',
    VIDEO_YOUTUBE = 'VIDEO_YOUTUBE'
}

export const ModalReducer: Reducer<ModalState, PayloadAction<any>> = (state = State, action: PayloadAction<any>): ModalState => {
    switch (action.type) {
        case ModalTypes.MODAL_ADD:
            return state = {
                ...state,
                data: action.payload
            }
        case ModalTypes.MODAL_DELETE:
            return state = {
                ...state,
                data: action.payload
            }
        case ModalTypes.OPEN_MODAL:
            return state = {
                ...state,
                event: action.payload
            }
        case ModalTypes.RESET_EVENT:
            return state = {
                ...state,
                event: action.payload
            }
        default:
            return state
    }
}
