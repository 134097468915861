import {
    ICommonNumberObject,
    ICommonStringObject
} from './constantsInterfaces';


export enum ProductTypes {
    CATEGORY = 'CATEGORY',
    SUBCATEGORY = 'SUBCATEGORY',
    OPTIONS = 'OPTIONS',
    PRODUCT = 'PRODUCT',
    CUSTOM = 'CUSTOM',
    REORDER = 'REORDER',
    OTHER = 'OTHER',
    ADDITIONAL = 'ADDITIONAL',
    ANOTHER = 'ANOTHER',
    NEED_MORE_PRODUCTS = 'NEED_MORE_PRODUCTS',
};

export const ORDER_STATUSES: ICommonNumberObject = {
    PENDING: 0,
    PAID: 1
};

export enum FieldType {
    Input = "input",
    Select = "select",
    Checkbox = "checkbox",
    Date = "date",
    DateRange = "date_range"
}