import {PayloadAction, Reducer} from "@reduxjs/toolkit"

export type FileState = {
    file: Blob,
    uuid: string
}

const State: FileState = {
    file: new Blob(),
    uuid: ""
}

export enum ActionTypes {
    FILE_SIMPLE = "FILE_SIMPLE",
    FILE_UPLOAD = "FILE_UPLOAD",
}

export const ProductReducer: Reducer<FileState, PayloadAction<any>> = (state = State, action: PayloadAction<any>): FileState => {
    switch (action.type) {
        case ActionTypes.FILE_SIMPLE:
            return state = {
                ...state,
                file: action.payload,
            }
        case ActionTypes.FILE_UPLOAD:
            return state = {
                ...state,
                uuid: action.payload,
            }
        default:
            return state
    }
}

