import {CommonContent, ContentTypes, Product} from "../entities/Product/Product";
import {Dealership} from "../entities/Users/Dealership";
import {ProductTypes} from "../../common/constants";
import {ProductRequest} from "../entities/Client/CheckoutRequest";

interface TotalPriceResult {
    totalPrice: number;
    subtotal: number;
    shippingCost: number;
    taxCost: number;
}

export class ProductCostCalculateUtils {

    static getPrice(elem: Product, selectedDealer: Dealership | undefined, contentType: ContentTypes): number | undefined {
        if (elem.contentCommonDTO) {
            return this.getValueDiscount(elem.contentCommonDTO.filter(v => v.type === contentType), selectedDealer);
        } else {
            return undefined;
        }
    }

    static getValueDiscount(productDealers: Array<CommonContent>, selectedDealer: Dealership | undefined): number | undefined {
        let discountProduct = undefined;
        if (selectedDealer) {
            if (productDealers && selectedDealer && selectedDealer.name) {
                let current = selectedDealer.name
                let contentDiscount = productDealers.find((item) => item.title === current);
                if (contentDiscount) {
                    discountProduct = Number(contentDiscount.value);
                }
            }
        }
        return discountProduct;
    }

    static getProductOptionsShipping(products: Array<Product>): Map<number, Product[]> {
        const productMap = new Map<number, Product[]>();
        products.forEach((elem) => {
            if (elem.parentShippingCost !== 0 && elem.parentType === ProductTypes.OPTIONS) {
                const parentId = elem.parentId;

                if (!productMap.has(parentId)) {
                    productMap.set(parentId, []);
                }
                productMap.get(parentId)?.push(elem);
            }
        })
        return productMap;
    }

    static getCountQuantityOptions(products: Array<Product>, elem: Product) {
        let productGroup = this.getProductOptionsShipping(products);
        let groupProductElem = productGroup.get(elem.parentId);
        if (groupProductElem) {
            return groupProductElem.reduce((sum, product) => sum + product.quantity, 0)
        }
        return 0;
    }

    static getMapQuantity(productMap: Map<number, Product[]>, selectedDealer: Dealership | undefined): Map<number, number> {
        const quantitySumMap = new Map<number, number>();
        productMap.forEach((productList, parentId) => {
            const quantitySum = productList.reduce((sum, product) => sum + product.quantity, 0);
            let shippingCommon = this.calculateShippingCost(productList[0], selectedDealer, quantitySum)
            quantitySumMap.set(parentId, shippingCommon);
        });
        return quantitySumMap;
    }

    static getTaxShow(selectedDealer: Dealership | undefined): string {
        if (selectedDealer) {
            if (selectedDealer.noTax) {
                return "no tax"
            } else {
                return `${selectedDealer?.shippingAddress?.state.name} ${selectedDealer?.shippingAddress?.state.tax}%`
            }
        }
        return "no selected"
    }

    static calculatePrice(elem: Product, selectedDealer: Dealership | undefined): number {
        let discountedPrice = elem.price * (1 + (((this.getPrice(elem, selectedDealer, ContentTypes.MARKUP_DEALER) || 0) - (this.getPrice(elem, selectedDealer, ContentTypes.DISCOUNT_DEALER) || 0)) / 100));
        return parseFloat(discountedPrice.toFixed(2));
    }

    static calculateShippingCost(elem: Product, selectedDealer: Dealership | undefined, quantity?: number, localTes?: boolean): number {
        if (selectedDealer && selectedDealer.noShippingFee) {
            return 0;
        }
        let discountShippingProduct = 1;
        let shippingDiscount = this.getPrice(elem, selectedDealer, ContentTypes.NO_SHIPP_DEALER)
        if (shippingDiscount !== undefined) {
            discountShippingProduct = (shippingDiscount / 100)
        }

        let uomData = quantity ? {
                minSize: elem.parentShippingMinSize,
                cost: ((selectedDealer?.localStatus && elem.parentShippingLocal > 0) || localTes) ? elem.parentShippingLocal : elem.parentShippingCost,
                step: elem.parentShippingStep
            }
            :
            {
                minSize: elem.shippingMinSize,
                cost: ((selectedDealer?.localStatus && elem.shippingLocal > 0) || localTes) ? elem.shippingLocal : elem.shippingCost,
                step: elem.shippingStep
            };
        let qnt = quantity || elem.quantity
        let countUom = (Math.floor(qnt / uomData.minSize) + (qnt % uomData.minSize > 0 ? 1 : 0))
        let firstUomCost = discountShippingProduct * uomData.cost;
        if (qnt > uomData.minSize) {
            let out
            if (uomData.step > 0) {
                out = firstUomCost + firstUomCost * Math.max(0, countUom - 1) * (Number(uomData.step) / 100)
            } else {
                out = firstUomCost * countUom;
            }
            return out
        } else {
            return firstUomCost;
        }
    }

    static calculateTaxCost(elem: Product, selectedDealer: Dealership | undefined): number {
        if (selectedDealer && selectedDealer && selectedDealer.id && selectedDealer?.shippingAddress?.state?.tax) {
            if (selectedDealer.noTax) {
                return 0;
            }
            if (elem.noTax) {
                return 0;
            }
            let taxPrice = 1;
            let taxDiscount = this.getPrice(elem, selectedDealer, ContentTypes.TAX_DEALER)
            if (taxDiscount !== undefined) {
                taxPrice = (taxDiscount / 100)
            }
            return this.calculatePrice(elem, selectedDealer) * elem.quantity * taxPrice * (selectedDealer?.shippingAddress?.state?.tax / 100) ?? 0;
        }
        return 0;
    }

    static calculateTaxCostNewUser(elem: Product, tax: number): number {
        if (tax) {
            return elem.price * elem.quantity * (tax / 100) ?? 0;
        }
        return 0;
    }

    static calculateTotalPrice(updatedProductList: Array<Product>, dealer: Dealership | undefined, tax?: number): TotalPriceResult {
        let subtotal = 0;
        let shippingCost = 0;
        let taxCost = 0;
        const groupProduct = this.getProductOptionsShipping(updatedProductList);

        updatedProductList.forEach(elem => {
            let price = dealer ? this.calculatePrice(elem, dealer) : elem.price;
            subtotal += price * elem.quantity;
            if (!groupProduct.has(elem.parentId)) {
                shippingCost += this.calculateShippingCost(elem, dealer);
            }
            taxCost += tax ? this.calculateTaxCostNewUser(elem, tax) : this.calculateTaxCost(elem, dealer);
        });

        this.getMapQuantity(groupProduct, dealer).forEach(quantitySum => {
            shippingCost += quantitySum;
        });

        const totalPrice = subtotal + shippingCost + taxCost;

        return {
            totalPrice,
            subtotal,
            shippingCost,
            taxCost,
        };
    }

    static getProductsRequest(updatedProductList: Array<Product>, dealer: Dealership | undefined, tax?: number): Array<ProductRequest> {
        const ProductRequestList: Array<ProductRequest> = [];
        for (const elem of updatedProductList) {
            const productRequest: ProductRequest = {
                id: elem.id,
                quantity: elem.quantity,
                price: elem.price,
                finalPrice: tax ? elem.price : ProductCostCalculateUtils.calculatePrice(elem, dealer),
                name: elem.name,
                discount: tax ? 0 : ((ProductCostCalculateUtils.getPrice(elem, dealer, ContentTypes.MARKUP_DEALER) || 0) - (ProductCostCalculateUtils.getPrice(elem, dealer, ContentTypes.DISCOUNT_DEALER) || 0)),
                shippingCost: (elem.parentShippingCost !== 0 && elem.parentType === ProductTypes.OPTIONS) ? -1 :
                    Number(ProductCostCalculateUtils.calculateShippingCost(elem, dealer).toFixed(2)),
                taxCost: Number((tax ? ProductCostCalculateUtils.calculateTaxCostNewUser(elem, tax) : ProductCostCalculateUtils.calculateTaxCost(elem, dealer)).toFixed(2)),
                amount: Number(((tax ? elem.price : ProductCostCalculateUtils.calculatePrice(elem, dealer)) * elem.quantity).toFixed(2))
            };
            ProductRequestList.push(productRequest);
        }
        return ProductRequestList;
    }

}