import {ChakraTheme} from "@chakra-ui/react";

export const globalStyles: Partial<ChakraTheme> = {
    colors: {
        gray: {
            800: "#1F2937",
            200: "#D1D5DB",
            100: "#F3F4F6"
        },
        red: {
            base: "#D52E34"
        },
        primary: {
            general: "#2EA3F8",
            gray: {
                light: "#F3F4F6"
            },
            heading: "#1F2937",
            text: "#4B5563",
        }
    },
    fonts: {
        fontFamily: 'Poppins, sans-serif',
    },
    fontSizes: {
        sm: 14,
        xl: 22,
    },
    fontWeights: {
        sm: 400,
        md: 600
    },
    styles: {
        global: (props: any) => ({
            body: {
                // bg: mode("red.50", "gray.800")(props),
                fontFamily: 'Poppins, sans-serif'
            }
        }),
    }
};
