import {PayloadAction, Reducer} from "@reduxjs/toolkit"
import {State} from "../../shared/entities/Users/User";

export type StatesState = {
    stateList: Array<State> | [],
    currentState: State
}

const States: StatesState = {
    stateList: [],
    currentState: {id: -1, code: "NT", name: "Default", tax: 0},
}

export enum ActionTypes {
    STATE_LIST = "STATE_LIST",
    SET_STATE = "SET_STATE",
}

export const StatesReducer: Reducer<StatesState, PayloadAction<any>> = (state = States, action: PayloadAction<any>): StatesState => {
    switch (action.type) {
        case ActionTypes.STATE_LIST:
            return state = {
                ...state,
                stateList: action.payload,
            }
        case ActionTypes.SET_STATE:
            return state = {
                ...state,
                currentState: action.payload,
            }
        default:
            return state
    }
}

