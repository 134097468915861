import {CustomError} from "../../shared/errors/CustomErrors";
import {Option} from "../../shared/utilities/OptionT";
import {APIDao, Requester} from "../APIRequester";
/**
 * ProductRepository.
 *
 * Responsible for fetching data from backend
 * by callind APIDao and handling various data
 * coming from API
 *
 * Data is passed to ClientService in a form of
 * an @type Option<T> type, where @param{Some: T} is either
 * a valid data, expected from the server,
 * or, in case of error, a default safe value,
 * for an object of array (kinda like default
 * values for types in Go),and @param{None: CustomError}
 * is either an error or undefined
 */
export class FileRepository {
    private dao: Requester

    constructor(dao: Requester) {
        this.dao = dao
    }

    /**
     * getFile.
     *
     * @returns {Promise<Option<Blob>}
     *
     */
    public async getFile(uuid: number | string, size: string): Promise<Option<Blob> | null> {
        const params = {
            size: size
        };

        return await this.dao.getRequest<Blob>(`file/${uuid}`, {params, responseType: 'blob'})
            .then(res => {
                const result: Option<Blob> = {
                    Some: res?.data
                };
                return result;
            })
            .catch((err: CustomError) => {
                const result: Option<Blob> = {
                    Some: new Blob(),
                    None: err
                };
                return result;
            });
    }

    public async getFileName(fileName: string): Promise<Option<Blob> | null> {
        const params = {
            fileName: fileName
        };

        return await this.dao.getRequest<Blob>(`file-name`, {params, responseType: 'blob'})
            .then(res => {
                const result: Option<Blob> = {
                    Some: res?.data
                };
                return result;
            })
            .catch((err: CustomError) => {
                const result: Option<Blob> = {
                    Some: new Blob(),
                    None: err
                };
                return result;
            });
    }

    /**
     * uploadFile.
     *
     * @param {number | string} customerId
     * @param {File} file
     * @param {string} fileType
     * @returns {Promise<void>}
     *
     */
    public async uploadFile(customerId: number | string, file: File, fileType: string): Promise<Option<any>> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileType', fileType);
        return await this.dao.uploadFile<any>(`file?customerId=${customerId}`, formData)
            .then(res => {
                const result: Option<any> = {
                    Some: res?.data,
                }
                return result
            })
            .catch((err: CustomError) => {
                const result: Option<any> = {
                    Some: {} as -1,
                    None: err
                }
                return result
            })
    }

    public async uploadFileUrl(customerId: number | string, url: URL, fileType: string): Promise<Option<any>> {
        return await this.dao.postRequest<any>(`file/upload-url-image`, {
            params: {
                imageUrl: url,
                fileType: fileType,
                customerId: customerId
            }
        })
            .then(res => {
                const result: Option<any> = {
                    Some: res?.data,
                }
                return result
            })
            .catch((err: CustomError) => {
                const result: Option<any> = {
                    Some: {} as -1,
                    None: err
                }
                return result
            })
    }
}

export const FileRepositoryInstance = new FileRepository(APIDao)
