import React, {useEffect, useState} from 'react';
import {Box, Flex, Text, useToast} from '@chakra-ui/react';
import {Content, Product} from '../../../shared/entities/Product/Product';
import {usePrivileges} from "../../../hooks/PrivilegesProvider";
import EditButtons from "../../../components/Buttons/EditButtons";
import ImageCustom from "../../../components/Image/ImageCustom";
import DragAndDropBox from "../../../components/DragAndDrop/DragAndDropBox";
import {useNavigate} from "react-router";
import ProductVideo from "../ProductVideo";
import {URLPaths} from "../../../config/application/URLPaths";
import {FileController} from "../../../controllers/FileController";
import {useDispatch} from "react-redux";
import {CardBody} from "../../../components/Card/CardBody";
import {MainButton} from "../../../components/Buttons/MainButton";
import IndexedDBService from "../../../service/IndexedDBService";

interface AnotherGeneralItemProps {
    product: Product;
    height?: string;
    width?: string;
    divider?: number;
    isMobile?: boolean;
}

const AnotherGeneralItem: React.FC<AnotherGeneralItemProps> = ({
                                                                   product,
                                                                   height,
                                                                   width = "100%",
                                                                   divider = 1,
                                                                   isMobile = false
                                                               }) => {
    const {priv} = usePrivileges()
    const navigate = useNavigate();
    const [pdfUrl, setPdfUrl] = useState<Blob>();
    const dispatch = useDispatch();
    const fileName = 'Permaplate_v2';
    const filesToDelete = ['Dealer Presentation Book & Training - National Team']; // Список старых файлов для удаления

    const fileController = new FileController(dispatch);
    const toast = useToast()
    const indexedDBService = new IndexedDBService('FileStorage', 'files');
    const handleLink = (elem: Product) => {
        if (elem.id === 1000521) {
            if (!pdfUrl) {
                toast({
                    position: "top",
                    title: 'No loading catalog',
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                })
            } else {
                window.open(URL.createObjectURL(pdfUrl) + '#toolbar=1&menubar=0', "_blank");
            }
        } else {
            navigate(`${URLPaths.PRODUCTS.link}${elem.id}`)
        }
    };
    const fetchAndCacheFile = async () => {

        try {
            // Попытка загрузить файл из IndexedDB
            let fileBlob = await indexedDBService.loadFile(fileName);
            if (!fileBlob || fileBlob.size === 0) {
                // Если файл не найден в IndexedDB, удаляем файлы из списка
                await indexedDBService.deleteFiles(filesToDelete);
                // Если файл не найден в IndexedDB, загружаем его
                const res = await fileController.getFileName(fileName);
                if (res) {
                    fileBlob = new Blob([res.Some], {type: 'application/pdf'});

                    // Сохраняем файл в IndexedDB
                    await indexedDBService.saveFile(fileName, fileBlob);
                }
            }
            if (fileBlob) {
                setPdfUrl(fileBlob);
            }
        } catch (error) {
            console.error('Error fetching or saving the file:', error);
        }
    }

    useEffect(() => {
        if (product.id === 1000521) {
            fetchAndCacheFile();
        }
    }, [product]);


    return (
        <Flex justifyContent={isMobile ? "unset" : "center"}>
            <Box display="flex" direction={{base: "column", md: "row"}} height={"100%"}
                 w={{base: "100%", md: "unset", lg: "unset"}}>
                {priv?.PRODUCT_EDIT &&
                    <DragAndDropBox item={product} width={"2%"} orderByZone={true} height={height}/>
                }
                <CardBody height={{base: "100%", md: "100%", lg: height}}>
                    <DragAndDropBox item={product} isDraggable={priv?.PRODUCT_EDIT} row={true}>
                        <Flex h={"100%"} w={isMobile ? "100%" : "unset"} direction={{base: "column", md: "row"}}>
                            <Flex w={isMobile ? "100%" : "426px"} maxWidth={"426px"}>
                                <Box pl={10} py={6} pr={6}>
                                    <EditButtons item={product}/>
                                    <Flex pb={2}>
                                        <Text fontSize={22} fontWeight={"md"}>
                                            {product.name}
                                        </Text>
                                    </Flex>
                                    {product.video.length > 0 && (
                                        product.video
                                            .sort((a, b) => a.order - b.order)
                                            .map((video: Content, idx: number) => {
                                                return <ProductVideo key={video.id || idx} title={true} video={video}/>
                                            })
                                    )}
                                    <Text fontWeight={"sm"} fontSize={14} lineHeight={"150%"}>
                                        {product.description}
                                    </Text>
                                </Box>
                            </Flex>
                            <Box w={{base: "100%", md: "200px"}} onClick={() => handleLink(product)}>
                                <Flex w={{base: "90%", md: "200px"}} mx={"auto"}>
                                    <ImageCustom elem={product} currentSize={"another"} noHandleLink={true}
                                                 isMobail={isMobile}
                                                 sizeBackend={"GENERAL"}/>
                                </Flex>
                                <Flex p={6} justifyContent="center">
                                    <MainButton
                                        onClick={() => handleLink(product)}>{product.name === 'Nitrogen' ? 'Order' : 'Read More'}</MainButton>
                                </Flex>
                            </Box>
                        </Flex>
                    </DragAndDropBox>
                </CardBody>
            </Box>
        </Flex>
    );
};

export default AnotherGeneralItem;
