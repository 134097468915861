import {PayloadAction, Reducer} from "@reduxjs/toolkit"


export type DragAndDropState = {
    idDrag: any,
    changeDragAccept: any,
};

const initialState: DragAndDropState = {
    idDrag: null,
    changeDragAccept: false,
};

export enum ActionTypes {
    SET_ID = "SET_ID",
    CHANGE_PRODUCT = "CHANGE_PRODUCT"
}

export const DragAndDropReducer: Reducer<DragAndDropState, PayloadAction<any>> = (state = initialState, action: PayloadAction<any>): DragAndDropState => {

    switch (action.type) {
        case ActionTypes.SET_ID:
            return state = {
                ...state,
                idDrag: action.payload,
            }
        case ActionTypes.CHANGE_PRODUCT:
            // console.log("info", action.payload, state.changeDragAccept)
            return state = {
                ...state,
                changeDragAccept: !state.changeDragAccept,
            }
        default:
            return state;
    }

};