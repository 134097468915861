import {AuthorizationData} from "../../shared/entities/Auth/AuthorizationData";
import {Privileges} from "../../shared/entities/Role/Privileges";
import {RoleList} from "../../shared/entities/Role/Role";
import {CustomError} from "../../shared/errors/CustomErrors";
import {Option} from "../../shared/utilities/OptionT";
import {APIDao, Requester} from "../APIRequester";

class AuthorizationRepository<T> {
    private dao: Requester

    constructor(dao: Requester) {
        this.dao = dao
    }

    public async authorize(data: any): Promise<Option<RoleList>> {
        sessionStorage.setItem("LOGIN", data.loginNoCrypt)
        delete data.loginNoCrypt;
        sessionStorage.setItem('lastRefreshTime', new Date().getTime().toString());

        return await this.dao.postRequest<RoleList>("admin/auth-user", {data}).then(res => {
            const result: Option<RoleList> = {
                Some: res?.data
            }
            return result
        }).catch((err: CustomError) => {
            sessionStorage.removeItem("LOGIN")
            sessionStorage.removeItem("DEALERSHIP")
            sessionStorage.removeItem("lastRefreshTime")
            const result: Option<RoleList> = {
                Some: {ROLES: []},
                None: err
            }
            return result
        })
    }

    public async getPublicKey(): Promise<Option<any>> {
        return await this.dao.getRequest<any>("admin/key", {params: {}})
            .then(res => {
                const result: Option<any> = {
                    Some: res?.data
                }
                return result
            }).catch((err: CustomError) => {
                const result: Option<any> = {
                    //@ts-ignore
                    Some: [],
                    None: err
                }
                return result
            })
    }

    public async checkAuh(): Promise<Option<Array<Privileges>>> {
        return await this.dao.postRequest<{
            USER_LOGIN: String,
            USER_PRIVILEGES: Array<Privileges>
        }>("admin/validate", {}).then(res => {
            sessionStorage.setItem("LOGIN", String(res?.data?.USER_LOGIN))
            const result: Option<Array<Privileges>> = {
                Some: res?.data?.USER_PRIVILEGES
            }
            return result
        }).catch((err: CustomError) => {
            sessionStorage.removeItem("LOGIN")
            sessionStorage.removeItem("DEALERSHIP")
            sessionStorage.removeItem("lastRefreshTime")
            const result: Option<Array<Privileges>> = {
                Some: [],
                None: err
            }
            return result
        })
    }

    public async refresh(): Promise<Option<boolean>> {
        return await this.dao.postRequest("admin/refresh", {}).then(res => {
            const result: Option<boolean> = {
                Some: true
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<boolean> = {
                Some: false,
                None: err
            }
            return result
        })
    }

    public async logOut(): Promise<Option<boolean>> {
        return await this.dao.postRequest("admin/logout", {}).then(res => {
            const result: Option<boolean> = {
                Some: true
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<boolean> = {
                Some: false,
                None: err
            }
            return result
        })
    }
}

export const AuthorizationRepositoryInstance = new AuthorizationRepository<AuthorizationData>(APIDao)
