import {Button, ChakraProps, Flex} from "@chakra-ui/react";
import {FC, ReactNode} from "react";
import {AngleDoubleLeft, AngleDoubleRight, AngleLeft, AngleRight} from "../Inputs/Icons/Icons";

export type SwapPageTrigger = (position: number) => void

/**
 * Пагинатор
 * @param param0
 * @returns
 */
export const Paginator: FC<{ limit: number, step: number, current: number, swapPageTrigger: SwapPageTrigger }> = ({
                                                                                                                      limit,
                                                                                                                      step,
                                                                                                                      current,
                                                                                                                      swapPageTrigger
                                                                                                                  }) => {
    const PaginatorButtonStyleConfig: ChakraProps = {
        bg: "white",
        color: "#5A5A5A",
        padding: "0px",
        fontFamily: "Poppins",
        fontWeight: "sm",
        fontSize: "16px",
        _hover: {
            color: "gray.200",
        },
        _active: {
            bg: "white",
            color: "gray.800",
        }
    }

    const PaginatorStyleConfig: ChakraProps = {
        flexDir: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        mt: "auto",
        mb: "0px"
    }
    const numericButtonsAmount: number = Math.round(limit / step >= 5 ? 5 : limit % step !== 0 ? Math.ceil(limit / step) : limit / step)
    /**
     * Функция расчета номеров в кнопках пагинатора
     */
    const buttonsList: ReactNode = new Array(numericButtonsAmount).fill(numericButtonsAmount).map((_, idx) => {
        return <Button key={idx + 1} {...PaginatorButtonStyleConfig}
                       isActive={current <= 3 ? current === idx + 1 : idx === 2}
                       onClick={() => (current - 3 + idx) * 10 >= limit ? undefined : swapPageTrigger(current <= 3 ? idx + 1 : current - 2 + idx)}>
            {current <= 3 ? idx + 1 : current - 2 + idx}
        </Button>
    })

    return (
        <Flex {...PaginatorStyleConfig}>
            <Button {...PaginatorButtonStyleConfig} children={<AngleLeft boxSize={6}/>}
                    onClick={() => swapPageTrigger(1)}/>
            <Button {...PaginatorButtonStyleConfig} children={<AngleDoubleLeft boxSize={6}/>}
                    onClick={() => current > 1 ? swapPageTrigger(current - 1) : undefined}/>
            {buttonsList}
            <Button {...PaginatorButtonStyleConfig} children={<AngleDoubleRight boxSize={6}/>}
                    onClick={() => current < Math.ceil(limit / step) ? swapPageTrigger(current + 1) : undefined}/>
            <Button {...PaginatorButtonStyleConfig} children={<AngleRight boxSize={6}/>}
                    onClick={() => swapPageTrigger(Math.ceil(limit / step))}/>
        </Flex>
    )
}
