import {PayloadAction} from "@reduxjs/toolkit"
import {Reducer} from "react"
import {User} from "../../shared/entities/Users/User"
import {Dealership, DealershipMinInfo} from "../../shared/entities/Users/Dealership";
import {RegistrationRequest} from "../../shared/entities/registrationRequest/RegistrationRequest";
import {Company} from "../../shared/entities/Company/Company";

export type UserState = {
    userList: Array<User>
    user?: User,
    company?: Company,
    dealership?: Dealership,
    dealershipsMinInfo: Array<DealershipMinInfo>,
    dealershipList: { dealerships: Array<Dealership>, count: number },
    userListAll: { users: Array<User>, count: number }
    requestListAll: { requests: Array<RegistrationRequest>, count: number }
    companyAll: { companies: Array<Company>, count: number }
    loginForCode?: string
}

const State: UserState = {
    userList: [],
    dealershipList: {
        dealerships: [],
        count: 0
    },
    user: undefined,
    dealership: undefined,
    company: undefined,
    dealershipsMinInfo: [],
    userListAll: {
        users: [],
        count: 0
    },
    requestListAll: {
        requests: [],
        count: 0
    },
    companyAll: {
        companies: [],
        count: 0
    },
    loginForCode: ''
}

export enum ActionTypes {
    USER_LIST = "USER_LIST",
    USER_LIST_ALL = "USER_LIST_ALL",
    USER_GET = "USER_GET",
    USER_CREATE = "USER_CREATE",
    USER_UPDATE = "USER_UPDATE",

    REQUEST_REGISTRATION = "REQUEST_REGISTRATION",
    CHANGE_PASSWORD = "CHANGE_PASSWORD",

    DEALERSHIP_GET = "DEALERSHIP_GET",
    DEALERSHIP_LIST = "DEALERSHIP_LIST",
    DEALERSHIP_MIN_INFO_LIST = "DEALERSHIP_MIN_INFO_LIST",

    COMPANY_LIST = "COMPANY_LIST",
    COMPANY = "COMPANY",
    CODE_LOGIN = "CODE_LOGIN"
}

export const UserReducer: Reducer<UserState, PayloadAction<any>> = (state = State, action: PayloadAction<any>): UserState => {
    switch (action.type) {
        case ActionTypes.USER_LIST:
            return state = {
                ...state,
                userList: action.payload,
            }
        case ActionTypes.USER_LIST_ALL:
            return state = {
                ...state,
                userListAll: action.payload,
            }
        case ActionTypes.REQUEST_REGISTRATION:
            return state = {
                ...state,
                requestListAll: action.payload,
            }
        case ActionTypes.USER_GET:
            return state = {
                ...state,
                user: action.payload
            }
        case ActionTypes.DEALERSHIP_GET:
            return state = {
                ...state,
                dealership: action.payload
            }
        case ActionTypes.DEALERSHIP_LIST:
            return state = {
                ...state,
                dealershipList: action.payload,
            }
        case ActionTypes.DEALERSHIP_MIN_INFO_LIST:
            return state = {
                ...state,
                dealershipsMinInfo: action.payload,
            }
        case ActionTypes.COMPANY_LIST:
            return state = {
                ...state,
                companyAll: action.payload,
            }
        case ActionTypes.COMPANY:
            return state = {
                ...state,
                company: action.payload
            }
        case ActionTypes.CODE_LOGIN:
            return state = {
                ...state,
                loginForCode: action.payload
            }
        default:
            return state
    }
}


