import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { URLPaths } from "../../config/application/URLPaths";
import {CustomLink} from "./CustomLink";
import {useNavigate} from "react-router";
import {usePrivileges} from "../../hooks/PrivilegesProvider";
import {AuthorizationController} from "../../controllers/AuthController";
import {useDispatch} from "react-redux";
import {useAuth} from "../../hooks/AuthHook";


const MenuLinks: FC = () => {
    const navigate = useNavigate();
    const {priv} = usePrivileges();
    const dispatch = useDispatch();
    const {handleAuthChange} = useAuth();

    const authController = new AuthorizationController(dispatch);

    const LogOut = () => {
        authController.logOut().then((res) => {
            sessionStorage.clear();
            handleAuthChange(false);
            window.location.reload();
        });
        navigate(URLPaths.HOME.link);
    };

    const handleLinkClick = (path: string) => {
        navigate(path);
    };
    return (
        <Box fontSize={14} textAlign="left">
            {!priv?.ADMINISTRATOR_VIEW && (
                <CustomLink onClick={() => handleLinkClick(URLPaths.ACCOUNT.link)}>Account</CustomLink>
            )}
            {(priv?.USER_CREATE || priv?.USER_VIEW) && (
                <CustomLink onClick={() => handleLinkClick(URLPaths.COMPANIES.link)}>Company list</CustomLink>
            )}
            {priv?.ROLE_VIEW && (
                <CustomLink onClick={() => handleLinkClick(URLPaths.ROLES.link)}>Role list</CustomLink>
            )}
            <CustomLink onClick={LogOut}>Log Out</CustomLink>
        </Box>
    );
}

export default MenuLinks;
