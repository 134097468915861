import React, {FC} from "react";
import {
    Button,
    Popover, PopoverBody,
    PopoverContent,
    PopoverTrigger
} from "@chakra-ui/react";
import {URLPaths} from "../../config/application/URLPaths";
import {useLocation, useNavigate} from "react-router";
import {useAuth} from "../../hooks/AuthHook";
import MenuButton from "./MenuButton";
import MenuLinks from "./Menu";


const PopoverMenu: FC = () => {
    const {isAuth} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            {
                isAuth ?
                    <Popover trigger="hover" placement='bottom-start'>
                        <PopoverTrigger>
                            <Button bg={"transparent"} _hover={{bg: "transparent"}} width={"100%"}>
                                <MenuButton/>
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent
                            style={{
                                background: "#FFFFFF",
                                borderRadius: "5px",
                                border: "1.5px solid #6B7280",
                                boxShadow: "0px 4px 32px #B3B2B2",
                                width: "200px"
                            }}
                        >
                            <PopoverBody p={4}>
                                <MenuLinks/>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    :
                    <MenuButton onClick={() => {
                        if (!location.pathname.includes(URLPaths.AUTH.link)) {
                            navigate(URLPaths.AUTH.link + location.pathname);
                        }
                    }}/>
            }
        </>
    );
};
export default PopoverMenu;
