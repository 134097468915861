import React from 'react';
import {Product} from '../../../shared/entities/Product/Product';
import {ProductTypes} from "../../../common/constants";
import {Box, Grid, GridItem, useBreakpointValue} from "@chakra-ui/react";
import {ProductListManager} from "../../addaptive/ProductListManager";
import {usePrivileges} from "../../../hooks/PrivilegesProvider";
import {generalBox} from "../../../theme/foundations/breakpoints";
import NewProductComponent from "../NewProductComponent";
import ProductGeneralItem from "./ProductGeneralItem";
import CategoryGeneralItem from "./CategoryGeneralItem";
import AnotherGeneralItem from "./AnotherGeneralItem";
import ProductItem from "../ProductItem";
import NeedMoreProduct from "./NeedMoreProduct";

interface ProductSectionProps<T> {
    productList: Array<Product>;
    groupTypes: ProductTypes;
    someCondition?: boolean;
}

const CommonSection = <T extends {}>({productList, groupTypes, someCondition = false}: ProductSectionProps<T>) => {
    const {priv} = usePrivileges();
    let maxCount = useBreakpointValue({base: 1, sm: 2, md: 3, lg: 4, xl: 5, "2xl": 6})
    let isMobile = (maxCount === 1 || maxCount === 2 || maxCount === 3);
    // let divider = useBreakpointValue({base: 2, sm: 2, md: 1});
    let divider = 1;

    let selGroup = [groupTypes];
    let elemInfo
    switch (groupTypes) {
        case ProductTypes.SUBCATEGORY:
            elemInfo = generalBox.subcategory
            break
        case ProductTypes.CATEGORY:
            elemInfo = generalBox.category
            break
        case ProductTypes.ADDITIONAL:
            elemInfo = generalBox.additional
            break
        case ProductTypes.PRODUCT:
        case ProductTypes.OPTIONS:
        case ProductTypes.REORDER:
        case ProductTypes.CUSTOM:
            selGroup = [ProductTypes.PRODUCT, ProductTypes.OPTIONS, ProductTypes.CUSTOM, ProductTypes.REORDER, ProductTypes.NEED_MORE_PRODUCTS]
            divider = isMobile ? 2 : 1;
            elemInfo = generalBox.product
            break
        case ProductTypes.ANOTHER:
            selGroup = [ProductTypes.ANOTHER]
            divider = isMobile ? 2 : 1;
            elemInfo = generalBox.another
            break
        default:
            elemInfo = generalBox.subcategory
            break
    }
    let rows = ProductListManager.getRowsProducts(productList, 4, selGroup);
    if (someCondition) {
        rows = rows.slice(0, 1);
    }

    const matchWidth = divider ? elemInfo.width / divider : elemInfo.width;
    const matchHeight = divider ? elemInfo.height / divider : elemInfo.height;
    // const elWidth = `${elemInfo.width}px`;
    const elWidth = priv?.PRODUCT_EDIT ? `${matchWidth + (12)}px` : `${matchWidth}px`;
    const elementHeight = priv?.PRODUCT_EDIT ? `${matchHeight + (matchHeight * 8 / 100)}px` : `${matchHeight}px`;
    const pT = elemInfo.pT
    const pB = elemInfo.pB
    return (
        <Box justifyContent={"space-around"}>
            {rows.map((rowProducts, rowIndex) => (

                <Grid
                    key={rowIndex}
                    gap={priv?.PRODUCT_EDIT ? ((groupTypes === ProductTypes.ADDITIONAL || (groupTypes === ProductTypes.ANOTHER && rowProducts.length === 1))) ? 6 : 0 : isMobile ? 3 : 6}
                    // border={((groupTypes === ProductTypes.ADDITIONAL || (groupTypes === ProductTypes.ANOTHER && rowProducts.length === 1))) ? "1px solid white" : "unset"}
                    paddingTop={pT} paddingBottom={pB}
                    style={{gridTemplateColumns: `repeat(auto-fill, minmax(${elWidth}, 1fr))`}}
                >
                    {rowProducts.map((elem, idx) => (
                        <GridItem key={idx} minWidth={"100%"}
                                  colStart={((groupTypes === ProductTypes.ADDITIONAL || (groupTypes === ProductTypes.ANOTHER && rowProducts.length === 1))) ? 1 : undefined}
                                  colEnd={((groupTypes === ProductTypes.ADDITIONAL || (groupTypes === ProductTypes.ANOTHER && rowProducts.length === 1))) ? 3 : undefined}
                        >
                            {elem && elem.id === 0 ? (
                                <NewProductComponent product={elem} height={elementHeight}/>
                            ) : (
                                <>
                                    {(groupTypes === ProductTypes.SUBCATEGORY &&
                                        <ProductGeneralItem product={elem}
                                                            height={elementHeight}
                                                            divider={divider ? divider : 1} isMobile={isMobile}/>
                                    )}
                                    {(groupTypes === ProductTypes.CATEGORY &&
                                        <CategoryGeneralItem product={elem} height={"100%"}
                                                             divider={divider ? divider : 1} isMobile={isMobile}/>
                                    )}
                                    {/*                                    {(groupTypes === ProductTypes.ADDITIONAL &&
                                        <AdditionalGeneralItem product={elem} height={elementHeight}
                                                               width={elWidth}/>
                                    )}*/}
                                    {(groupTypes === ProductTypes.ANOTHER &&
                                        <AnotherGeneralItem product={elem} height={elementHeight} width={elWidth}
                                                            isMobile={isMobile}/>
                                    )}
                                    {((groupTypes === ProductTypes.PRODUCT && elem.type !== ProductTypes.NEED_MORE_PRODUCTS) &&
                                        <ProductItem key={idx} product={elem} isMobile={isMobile}/>
                                    )}
                                    {(groupTypes === ProductTypes.PRODUCT && elem.type === ProductTypes.NEED_MORE_PRODUCTS &&
                                        <NeedMoreProduct key={idx} product={elem} height={elWidth}/>
                                    )}
                                </>
                            )}
                        </GridItem>
                    ))}
                </Grid>
            ))}
        </Box>
    );
};

export default CommonSection;