import {PayloadAction, Reducer} from "@reduxjs/toolkit"
import {Product} from "../../shared/entities/Product/Product";
import {ProductCostCalculateUtils} from "../../shared/utilities/ProductCostCalculateUtils";

export type CartState = {
    cartItems: Array<Product> | [],
    subTotal: number;
};

const initialState: CartState = {
    cartItems: [],
    subTotal: 0,
};

export enum ActionTypes {
    ADD_TO_CART = "ADD_TO_CART",
    REMOVE_FROM_CART = "REMOVE_FROM_CART",
    REMOVE_ALL_CART = "REMOVE_ALL_CART",
}

// Сохранение корзины в куки
const saveCartToStorage = (cartItems: Array<Product>) => {
    // Cookies.set("cartItems", JSON.stringify(cartItems), {expires: 7});
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));

};

// Загрузка корзины из куки
const loadCartFromStorage = (): Array<Product> => {
    const savedCartItems = localStorage.getItem("cartItems");
    // const savedCartItems = sessionStorage.getItem("cartItems");
    // const savedCartItems = Cookies.get("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
};

export const CartReducer: Reducer<CartState, PayloadAction<Product>> = (
    state = {...initialState, cartItems: loadCartFromStorage(), subTotal: calculateSubTotal(loadCartFromStorage())},
    action: PayloadAction<Product>
) => {
    let updatedCartItems: Array<Product>;
    switch (action.type) {
        case ActionTypes.ADD_TO_CART:
            const product = action.payload as Product;
            const existingProduct = state.cartItems.find(
                (item) => item.id === product.id
            );
            if (existingProduct) {
                updatedCartItems = state.cartItems.map((item) =>
                    item.id === product.id
                        ? {...item, quantity: product.quantity}
                        : item
                );
            } else {
                updatedCartItems = [...state.cartItems, product];
            }
            saveCartToStorage(updatedCartItems);
            state.subTotal = calculateSubTotal(updatedCartItems);
            return {
                ...state,
                cartItems: updatedCartItems,
            };

        case ActionTypes.REMOVE_FROM_CART:
            updatedCartItems = state.cartItems.filter(
                (item) => item.id !== action.payload.id
            );
            saveCartToStorage(updatedCartItems);
            state.subTotal = calculateSubTotal(updatedCartItems);
            return {
                ...state,
                cartItems: updatedCartItems,
            };

        case ActionTypes.REMOVE_ALL_CART:
            updatedCartItems = [];
            saveCartToStorage(updatedCartItems);
            state.subTotal = calculateSubTotal(updatedCartItems);
            return {
                ...state,
                cartItems: updatedCartItems,
            };
        default:
            return state;
    }
};

const calculateSubTotal = (cartItems: Array<Product>): number => {
    let subtotal = 0;
    cartItems.forEach((product) => {
        subtotal += ProductCostCalculateUtils.calculatePrice(product, undefined) * product.quantity;
    });
    return subtotal;
};