import {Box, Icon, Tooltip} from "@chakra-ui/react";
import React from "react";

const IconWithTooltip: React.FC<{
    icon: any;
    tooltipMessage?: string;
    onClick?: () => void;
    isOutlined?: boolean;
    isHoverOutlined?: boolean;
    isDisabled?: boolean;
    color?: string;
    fontSize?: string;
}> = ({
          icon,
          tooltipMessage,
          onClick,
          fontSize = "1rem",
          isOutlined = false,
          isHoverOutlined = true,
          isDisabled = false,
          color
      }) => {

    return (
        <Tooltip fontSize={14} hasArrow label={tooltipMessage} bg="gray.300" color="black">
            <Box
                h={6}
                ml={"0.2rem"}
                _hover={{
                    borderRadius: "10%",
                    border: isHoverOutlined ? "0.2px solid gray" : "none",
                }}
                border={isOutlined ? "1px solid red" : "none"}
                borderRadius="10%"
                display="inline-block"
                onClick={onClick}
                opacity={isDisabled ? 0.5 : 1}
                cursor={isDisabled ? "not-allowed" : "pointer"}
                pointerEvents={isDisabled ? "none" : "auto"}
                color={color} // Устанавливаем цвет иконки
            >
                <Icon
                    as={icon}
                    strokeWidth={1}
                    fontSize={fontSize}
                    cursor={"pointer"}
                    color={color}
                />
            </Box>
        </Tooltip>
    );
};

export default IconWithTooltip;
