class IndexedDBService {
    private dbName: string;
    private storeName: string;
    private version: number;
    constructor(dbName: string, storeName: string, version: number = 1) {
        this.dbName = dbName;
        this.storeName = storeName;
        this.version = version;
    }

    private openDatabase(): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.version);

            request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                const db = (event.target as IDBOpenDBRequest).result;
                if (!db.objectStoreNames.contains(this.storeName)) {
                    db.createObjectStore(this.storeName, { keyPath: 'name' });
                }
            };

            request.onsuccess = (event: Event) => {
                resolve((event.target as IDBOpenDBRequest).result);
            };

            request.onerror = (event) => {
                reject((event.target as IDBOpenDBRequest).error);
            };
        });
    }

    public saveFile(fileName: string, blob: Blob): Promise<void> {
        return this.openDatabase().then(db => {
            return new Promise<void>((resolve, reject) => {
                const transaction = db.transaction(this.storeName, 'readwrite');
                const store = transaction.objectStore(this.storeName);
                const request = store.put({ name: fileName, content: blob });

                request.onsuccess = () => {
                    resolve();
                };

                request.onerror = (event) => {
                    reject((event.target as IDBRequest).error);
                };
            });
        });
    }

    public loadFile(fileName: string): Promise<Blob | null> {
        return this.openDatabase().then(db => {
            return new Promise<Blob | null>((resolve, reject) => {
                const transaction = db.transaction(this.storeName, 'readonly');
                const store = transaction.objectStore(this.storeName);
                const request = store.get(fileName);

                request.onsuccess = (event) => {
                    resolve((event.target as IDBRequest).result?.content ?? null);
                };

                request.onerror = (event) => {
                    reject((event.target as IDBRequest).error);
                };
            });
        });
    }

    public deleteFile(fileName: string): Promise<void> {
        return this.openDatabase().then(db => {
            return new Promise<void>((resolve, reject) => {
                const transaction = db.transaction(this.storeName, 'readwrite');
                const store = transaction.objectStore(this.storeName);
                const request = store.delete(fileName);

                request.onsuccess = () => {
                    resolve();
                };

                request.onerror = (event) => {
                    reject((event.target as IDBRequest).error);
                };
            });
        });
    }

    public deleteFiles(fileNames: string[]): Promise<void> {
        return Promise.all(fileNames.map(fileName => this.deleteFile(fileName))).then(() => {});
    }

}

export default IndexedDBService;