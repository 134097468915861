import React, {FC} from "react";
import {
    Box, Button, Popover, PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader, PopoverTrigger,
} from "@chakra-ui/react";
import {useAppSelector} from "../../redux/Store";
import Cart from "./Cart";
import CartButton from "./CartButton";

const PopoverCart: FC = () => {
    const cartItems = useAppSelector((state) => state.CartReducer.cartItems);

    return (
        <Popover trigger="hover" openDelay={200} closeDelay={200} placement='bottom-start'>
            <PopoverTrigger>
                <Button maxWidth="155px" _hover={{bg: "transparent"}}>
                    <CartButton/>
                </Button>
            </PopoverTrigger>
            <PopoverContent
                style={{
                    background: "#FFFFFF",
                    borderRadius: "5px",
                    border: "1.5px solid #6B7280",
                    boxShadow: "0px 4px 32px #B3B2B2",
                    maxHeight: "calc(67vh - 20px)",
                    minWidth: "400px",
                }}
            >
                <PopoverArrow/>
                <PopoverHeader display="flex" fontSize={14} fontWeight={"md"}>
                    <Box pr={1}>
                        {cartItems.length}
                    </Box>
                    Items in your cart
                </PopoverHeader>
                <PopoverBody>
                    <Cart onCheckout={() => {
                    }}/>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default PopoverCart;
