import {PayloadAction, Reducer} from "@reduxjs/toolkit"

export type NavBarState = {
    searchItems: { search: string },
    searchReset: boolean
};

const initialState: NavBarState = {
    searchItems: {search: ""},
    searchReset: false
};

export enum ActionTypes {
    SEARCH_TEXT = "SEARCH_TEXT",
    SEARCH_RESET = "SEARCH_RESET"
}

export const NavBarReducer: Reducer<NavBarState, PayloadAction<any>> = (state = initialState, action: PayloadAction<any>): NavBarState => {

    switch (action.type) {
        case ActionTypes.SEARCH_TEXT:
            return state = {
                ...state,
                searchItems: action.payload,
            }
        case ActionTypes.SEARCH_RESET:
            return state = {
                ...state,
                searchReset: action.payload,
            }
        default:
            return state;
    }

};
