import {PayloadAction, Reducer} from "@reduxjs/toolkit"
import {Product} from "../../shared/entities/Product/Product";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";

export type ProductState = {
    productList: Array<Product> | []
    productGet: Product | null
    productResponse: number | null
    productLinks: Array<BreadcrumbsData> | []
}

const State: ProductState = {
    productList: [],
    productGet: null,
    productResponse: null,
    productLinks:[]
}

export enum ActionTypes {
     PRODUCT_LIST = "PRODUCT_LIST",
     PRODUCT_GET = "PRODUCT_GET",
     PRODUCT_REMOVE = "PRODUCT_REMOVE",
     CLEAR_PRODUCT_LIST = "CLEAR_PRODUCT_LIST",
     CREATE_PRODUCT = "CREATE_PRODUCT",
     UPDATE_LINKS = "UPDATE_LINKS",
}

export const ProductReducer: Reducer<ProductState, PayloadAction<any>> = (state = State, action: PayloadAction<any>): ProductState => {
    switch (action.type) {
        case ActionTypes.PRODUCT_LIST:
            return state = {
                ...state,
                productList: action.payload,
            }
        case ActionTypes.PRODUCT_GET:
            return state = {
                ...state,
                productGet: action.payload
            }
        case ActionTypes.CLEAR_PRODUCT_LIST:
            return {
                ...state,
                productList: [],
            };
        case ActionTypes.CREATE_PRODUCT:
            return {
                ...state,
                productResponse: action.payload,
            };
        case ActionTypes.UPDATE_LINKS:
            return state = {
                ...state,
                productLinks: action.payload,
            }
        case ActionTypes.PRODUCT_REMOVE:
            return {
                ...state,
                productGet: null,
            };
        default:
            return state
    }
}

