import {Button, ButtonProps} from "@chakra-ui/react";
import {FC} from "react";

/**
 * Обычная функциональная кнопка, стилизованная по умолчнию
 * @param props
 * @returns
 */

export const MainButton: FC<ButtonProps> = (props) => {
    const ButtonStyleProps: ButtonProps = {
        color: "black",
        height: "28px",
        fontSize: "14px",
        fontFamily: "Poppins",
        background: "primary.gray.light",
        borderRadius: "5px",
        border: "1px",
        borderColor: "gray.200",
        ...props,
    };
    return (
        <Button {...ButtonStyleProps} />
    )
}
