import React from "react";
import {Box, Flex, Text} from "@chakra-ui/react";
import {FaYoutube} from "react-icons/fa";
import {Content} from "../../shared/entities/Product/Product";
import IconWithTooltip from "../../components/Icon/IconWithTooltip";
import {ModalService} from "../../service/Modal/ModalService";
import {SelectModal} from "../../redux/Modal/ModalReducer";
import {useDispatch} from "react-redux";

interface ProductVideoProps {
    video: Content,
    title?: boolean
}

const ProductVideo: React.FC<ProductVideoProps> = ({video, title = false}) => {
    const dispatch = useDispatch();
    const modalService = new ModalService(dispatch);

    return (
        <Box alignContent="center" >
            {video && (
                <Flex alignItems="center"
                      justifyContent={"flex-start"}>
                    <IconWithTooltip
                        icon={FaYoutube}
                        color="gray.200"
                        isHoverOutlined={false}
                        tooltipMessage={video.title ? video.title : 'Video 1'}
                        fontSize="1.6rem"
                        onClick={() => {
                            modalService.setModal({
                                selModel: SelectModal.VIDEO_YOUTUBE,
                                selCommonContent: video.value
                            })
                        }}
                    />
                    {title && (
                        <Text pl={2} fontSize={14} fontWeight={'sm'}> {video.title}</Text>
                    )}
                </Flex>
            )}
        </Box>
    );
};

export default ProductVideo;
