import {Link, LinkProps, Text} from "@chakra-ui/react";
import React, {FC} from "react";

/**
 * Обычная функциональная кнопка, стилизованная по умолчнию
 * @param props
 * @returns
 */

interface NavBarLinkButtonProps extends LinkProps {
    text: string;
}

const NavBarLinkButton: FC<NavBarLinkButtonProps> = ({ text, ...restProps }) => {
    const linkStyle = {
        color: "black.500",
        fontFamily: "Poppins",
        fontWeight: "400",
        lineHeight: "20px",
        textDecoration: "none",
        // display: "inline-block",
        marginRight: "5px",
        cursor: "pointer",
        _hover: {
            textDecoration: "underline",
            color: "blue.600",
        },
    };

    return (
        <Link {...linkStyle} {...restProps}>
            <Text whiteSpace="nowrap">
                {text}
            </Text>
        </Link>
    );
};

export default NavBarLinkButton;
