import {PayloadAction} from "@reduxjs/toolkit"
import {Dispatch} from "react"
import {ActionTypes} from "../../redux/State/StateReducer";
import {StateRepositoryInstance} from "../../repository/State/StateRepository";

/**
 * ClientService.
 *
 */
export class StateService {
    private dispatch: Dispatch<PayloadAction<any>>
    private repository = StateRepositoryInstance

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
    }

    public async getStates() {
        return this.repository.getStates().then(res => {
            this.dispatch({
                type: ActionTypes.STATE_LIST,
                payload: res?.Some || []
            })
            return res
        })
    }

}
