import {Box, CloseButton} from "@chakra-ui/react";
import {FC} from "react";

interface DarkOverlayProps {
    onClose: () => void;
    isMobile?: boolean;
}

const DarkOverlay: FC<DarkOverlayProps> = ({children, onClose, isMobile = false, ...rest}) => (
    <Box
        position="fixed"
        top="0"
        left="0"
        width={isMobile ? "100%" : "100vw"}
        height={isMobile ? "100%" : "100vh"}
        backgroundColor="rgba(0, 0, 0, 0.5)"
        display={isMobile ? "flow" : "flex"}
        justifyContent="center"
        alignItems="center"
        {...rest}
    >
        <Box position="relative">
            {children}
            <CloseButton onClick={onClose} position="absolute" top={isMobile ? "100%" : "-33"} right={isMobile ? "92%":"-33"} zIndex={9999}
                         bg="transparent"
                         color="white"
                         _hover={{bg: "red.500", color: "white"}}
            />
        </Box>
    </Box>
);

export default DarkOverlay;
