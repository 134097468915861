export class WebPUtils {
    static getProxy() {
        return process.env.NODE_ENV === "development" ? "http://localhost:8080/" : `https://backend.${window.location.host}/`;
    }
    static getIOSVersion(): [number, number, number] {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            const match = navigator.appVersion.match(/OS (\d+)_?(\d+)?_?(\d+)?/);
            if (match) {
                return [
                    parseInt(match[1], 10),
                    parseInt(match[2] || '0', 10),
                    parseInt(match[3] || '0', 10)
                ];
            }
        }
        return [0, 0, 0];
    }
    static canUseWebP() {
        const [majorIOSVersion] = this.getIOSVersion();

        if (majorIOSVersion > 0) {
            // iOS device detected
            if (majorIOSVersion >= 14) {
                return "WEB"; // iOS version 14 or higher
            } else {
                return "STANDARD"; // iOS version below 14
            }
        } else {
            // Not an iOS device, use canvas to check WebP support
            const elem = document.createElement('canvas');

            if (elem.getContext && elem.getContext('2d')) {
                // Check if the browser can render WebP
                if (elem.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
                    return "WEB";
                } else {
                    return "STANDARD";
                }
            } else {
                // Very old browser like IE 8, canvas not supported
                return "STANDARD";
            }
        }
    }
}