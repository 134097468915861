import React, {FC, useEffect, useState} from "react";
import {Box, Icon, Input, InputGroup, InputLeftElement, InputRightElement} from "@chakra-ui/react";
import {Product} from "../../shared/entities/Product/Product";
import {FiChevronDown, FiChevronUp, FiMinus, FiPlus} from "react-icons/fi";

type QuantityInputProps = {
    item: Product;
    isViewWithPath?: boolean;
    reset?: boolean;
    onQuantityChange: (updatedItem: Product) => void;
    width?: string | number;
    isMobile?: boolean;
};
const QuantityInput: FC<QuantityInputProps> = ({
                                                   item,
                                                   isViewWithPath = false,
                                                   reset = false,
                                                   onQuantityChange,
                                                   width = 97,
                                                   isMobile = false
                                               }) => {
    const [quantity, setQuantity] = useState<number>(item.quantity ? item.quantity : 0);
    if (item.quantity === undefined) {
        item.quantity = 0;
    }

    useEffect(() => {
        if (!reset) {
            setQuantity(item.quantity);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.quantity]);

    useEffect(() => {
        if (reset) {
            setQuantity(0);
        }
    }, [reset]);

    const handleQuantityIncrease = (item: Product, increase: boolean) => {

        if (increase) {
            setQuantity(prevQuantity => (reset ? 0 : prevQuantity) + (quantity === 0 ? item.startValue : item.quantityStep));
        } else {
            if (quantity > item.startValue) {
                setQuantity(prevQuantity => (reset ? 0 : prevQuantity) - item.quantityStep);
            } else {
                setQuantity(0);
            }
        }
    };

    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = parseInt(event.target.value, 10);
        if (!isNaN(newQuantity)) {
            setQuantity(newQuantity);
        }
    };

    useEffect(() => {
        const updatedItem = {...item};
        updatedItem.quantity = quantity;
        onQuantityChange(updatedItem);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity]);

    return (
        <InputGroup width={width} zIndex={0}>
            {isMobile &&
                <InputLeftElement height={"100%"}>
                    <FiMinus onClick={() => handleQuantityIncrease(item, false)} size={24}/>
                </InputLeftElement>
            }
            <Input type="number"
                   borderRadius="5px"
                   height={isMobile ? "30px" : "28px"}
                   fontSize="14px"
                   fontWeight={"sm"}
                   paddingRight={"1em"}
                   paddingLeft={"0.5em"}
                   borderColor={"gray.300"}
                   border={"1px"}
                   textAlign={isMobile ? "center" : "left"}
                   value={reset ? 0 : quantity}
                   min={0} onChange={handleQuantityChange}
                   isDisabled={isViewWithPath}
                   readOnly
            />
            {isMobile &&
                <InputRightElement height={"100%"}>
                    <FiPlus onClick={() => handleQuantityIncrease(item, true)} size={24}/>
                </InputRightElement>
            }
            {!isMobile &&
                <InputRightElement mr={-2} pb={3}>
                    <Box display="flex" flexDirection="column">
                        <Icon
                            boxSize={4}
                            as={FiChevronUp}
                            cursor={"pointer"}
                            onClick={() => handleQuantityIncrease(item, true)}
                        />
                        <Icon
                            as={FiChevronDown}
                            boxSize={4}
                            cursor={"pointer"}
                            onClick={() => handleQuantityIncrease(item, false)}
                        />
                    </Box>
                </InputRightElement>
            }
        </InputGroup>
    );
}

export default QuantityInput;