import React, {FC} from "react";
import {Button, Flex, Text} from "@chakra-ui/react";
import {useAppSelector} from "../../redux/Store";
import {generalColor} from "../../theme/foundations/breakpoints";

interface CartButtonProps {
    onClick?: () => void;
}

const CartButton: FC<CartButtonProps> = ({onClick}) => {
    const cartItems = useAppSelector((state) => state.CartReducer.cartItems);
    const subTotal = useAppSelector((state) => state.CartReducer.subTotal);

    return (
        <Button
            fontSize={14}
            borderRadius={6}
            bg={generalColor.primaryGrayLight}
            minWidth="auto"
            onClick={onClick}
            _hover={{textDecoration: "underline", bg: "transparent"}}
        >
            <Flex alignItems="center" fontWeight="md">
                <Text>Cart</Text>
                <Text pl={1} as="span">{'$'}{subTotal.toFixed(2)}</Text>
                <Text pl={1} as="span" color="gray.500">{`(${cartItems.length})`}</Text>
            </Flex>
        </Button>
    );
};

export default CartButton;
