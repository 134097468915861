import {ComponentStyleConfig} from "@chakra-ui/react";

const CardBody:Partial<ComponentStyleConfig> = {
  baseStyle: {
    display: "flex",
    width: "100%",
    border: "1px",
    borderColor:"gray.200",
    borderRadius:"lg"
  },
};

export const CardBodyComponent:any = {
  components: {
    CardBody,
  },
};
