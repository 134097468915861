import React from 'react';
import {Box, Flex} from '@chakra-ui/react';
import {Icon} from "@chakra-ui/icons";
import {URLPaths} from "../../config/application/URLPaths";
import {Product} from "../../shared/entities/Product/Product";
import {useNavigate, useParams} from "react-router";
import {PlusNewItem} from "../../components/Inputs/Icons/Icons";
import {usePrivileges} from "../../hooks/PrivilegesProvider";
import {CardBody} from "../../components/Card/CardBody";

type NewProductComponentProps = {
    product: Product;
    height?: string;
};

const NewProductComponent: React.FC<NewProductComponentProps> = ({product, height}) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const productId = id ? Number(id) : 0;
    const {priv} = usePrivileges();

    return (
        <Flex height={height}>
            {priv?.PRODUCT_EDIT &&
                <Box width={"5%"}/>
            }
            <CardBody width={priv?.PRODUCT_EDIT ? "95%" : "100%"}>
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    cursor={"pointer"}
                    onClick={() => navigate(`${URLPaths.PRODUCTS_CREATE.link}${productId}`)}
                >
                    <Icon as={PlusNewItem} fontSize={"xl"} color="grey.500"/>
                </Flex>
            </CardBody>
        </Flex>
    );
};

export default NewProductComponent;