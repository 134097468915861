import {useBreakpointValue, useDisclosure} from "@chakra-ui/react";
import React, {FC, useEffect, useState} from "react";
import {useAppSelector} from "../../redux/Store";
import {SelectModal} from "../../redux/Modal/ModalReducer";
import DarkOverlay from "../../components/Information/DarkOverlay";
import YouTube from "react-youtube";
import {ModalService} from "../../service/Modal/ModalService";
import {useDispatch} from "react-redux";

export const YouTubeModal: FC = () => {
    const event = useAppSelector(state => state.ModalReducer.event);
    const [selectedItems, setSelectedItems] = useState<string>();
    const {isOpen, onOpen, onClose} = useDisclosure()
    const dispatch = useDispatch()
    const modalService = new ModalService(dispatch)
    let isMobile = useBreakpointValue({base: true, sm: true, md: false, lg: false, xl: false, "2xl": false})

    useEffect(() => {
        if (event && event.selModel === SelectModal.VIDEO_YOUTUBE) {
            setSelectedItems(event.selCommonContent)
            onOpen()
        }
    }, [event]);

    const handleCloseClick = () => {
        modalService.resetModal();
        onClose();
    };

    return (
        isOpen ?
            <DarkOverlay onClose={handleCloseClick} isMobile={isMobile}>
                <YouTube videoId={selectedItems} opts={{
                    height: isMobile ? '240px' : "350px",
                    width: isMobile ? '100%' : "600px"
                }}/>
            </DarkOverlay>
            : <></>
    );
}
export default YouTubeModal;