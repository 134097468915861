import {PayloadAction, Reducer} from "@reduxjs/toolkit"
import {OrderProduct} from "../../shared/entities/OrderProduct/OrderProduct";

export type OrderProductState = {
    orderProductList: Array<OrderProduct> | []
}

const State: OrderProductState = {
    orderProductList: [],
}

export enum ActionTypes {
    ORDER_PRODUCTS_LIST = "ORDER_PRODUCTS_LIST",
}

export const OrderProductReducer: Reducer<OrderProductState, PayloadAction<any>> = (state = State, action: PayloadAction<any>): OrderProductState => {
    switch (action.type) {
        case ActionTypes.ORDER_PRODUCTS_LIST:
            return state = {
                ...state,
                orderProductList: action.payload,
            }
        default:
            return state
    }
}

