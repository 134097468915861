import {MapLike} from "typescript";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";


/**
 * Маппинги названий секций и их путей
 * используются в роутинге
 */
export const URLPaths: MapLike<BreadcrumbsData> = {
    HOME: {
        link: "/",
        text: "Home"
    },
    HOME_ARCHIVE: {
        link: "/archive/",
        text: "Archive products"
    },
    CONTACTS: {
        link: "/contacts",
        text: "Contacts"
    },
    ABOUT_US: {
        link: "/about_us",
        text: "About us"
    },
    GREAT_SAVE: {
        link: "/great_save",
        text: "Great save"
    },
    ORDER_BY_CATALOG: {
        link: "/order-by-catalog",
        text: "Order By Catalog"
    },
    CART: {
        link: "/cart",
        text: "Cart"
    },
    ORDER_VIEW: {
        link: "/order/view/",
        text: "Order View"
    },
    ORDER_UNREGISTERED: {
        link: "/order/unregistered/",
        text: "Order Unregistered"
    },
    ORDER_HISTORY: {
        link: "/order_history",
        text: "Order History"
    },
    ORDER_HISTORY_USER: {
        link: "/order_history/user",
        text: "Order History"
    },
    INVOICE: {
        link: "/invoice/",
        text: "Invoice"
    },

    AUTH: {
        link: "/auth",
        text: "Log In"
    },
    ACCOUNT: {
        link: "/account",
        text: "Account"
    },
    ACCOUNT_REGISTRATION: {
        link: "/account/registration",
        text: "Registration"
    },
    CHANGE_PASSWORD: {
        link: "/change/password",
        text: "Change password"
    },
    PASSWORD_RESTORE_CODE: {
        link: "/password-restore",
        text: "Passowrd restore"
    },
    PASSWORD_CREATE: {
        link: "/password-create",
        text: "Passowrd create"
    },
    RESTORE_PASSWORD: {
        link: "/restore/password",
        text: "Restore password"
    },
    COMPANY_VIEW: {
        link: "/company/view/",
        text: "View company"
    },
    COMPANY_EDIT: {
        link: "/company/edit/",
        text: "Edit company"
    },
    COMPANY_CREATE: {
        link: "/company/create/",
        text: "Create company"
    },
    COMPANIES: {
        link: "/companies/",
        text: "Companies"
    },
    ROLES: {
        link: "/roles",
        text: "Roles"
    },
    ROLES_CREATE: {
        link: "/accounts/roles/create",
        text: "Create role"
    },
    ROLES_VIEW: {
        link: "/accounts/roles/view/",
        text: "View role"
    },
    ROLES_EDIT: {
        link: "/accounts/roles/edit/",
        text: "Edit role"
    },

    PRODUCTS: {
        link: "/products/",
        text: "Service Supplies"
    },
    PRODUCTS_CREATE: {
        link: "/products/create/",
        text: "Create product card"
    },
    PRODUCTS_ARCHIVE: {
        link: "/archive/products/",
        text: "View product card"
    },
    PRODUCTS_NOT_DISTRIBUTED: {
        link: "/not_distributed/",
        text: "Products not distributed"
    },
    PRODUCTS_EDIT: {
        link: "/products/edit/",
        text: "Edit product card"
    }
}

