import {Box, Text, ChakraProps, Flex, Link} from "@chakra-ui/react";
import React, {FC} from "react";
import {URLPaths} from "../../config/application/URLPaths";
import {NavLink} from "react-router-dom";
import {Icon} from "@chakra-ui/icons";
import {CallIcon, LogoButton} from "../Inputs/Icons/Icons";
import NavBarLinkButton from "../Link/NavBarLinkButton";
import {useLocation, useNavigate} from "react-router";
import {generalColor} from "../../theme/foundations/breakpoints";

/**
 * Навигационная панель сайта
 * @returns
 */
export const NavbarBottom: FC = () => {
    const NavbarStyleConfig: ChakraProps = {
        h: {
            md: "85px",
            lg: "85px",
        },
        w: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        bottom: 0,
        transition: "all .2s",
    }
    const navigate = useNavigate();
    const location = useLocation();
    const isGreatSave = location.pathname.includes("great_save");

    return (
        <>
            <Box padding={0} paddingTop={!isGreatSave ? 20 : 0} paddingBottom={20}>
                {!isGreatSave &&
                    <>
                        <Box border={"0.7px solid #D1D5DB"} width={"100%"}/>
                        <Box paddingBottom={6}/>
                    </>
                }
                <Flex direction={{base: "column", lg: "row"}}>
                    <Box {...NavbarStyleConfig} flexDir="column" mx={{lg: "auto"}} width={{lg: "50%"}}>
                        {!isGreatSave &&
                            <NavLink to={URLPaths.HOME.link}>
                                <Icon as={LogoButton} width="214px" height={"24px"}/>
                            </NavLink>
                        }
                        <Text paddingTop={!isGreatSave ? 4 : 2} fontSize={14} fontWeight={"sm"} color={"gray.400"}
                        >Automotive Dealer Supply Company that manufactures most of its products and holds multiple US
                            Patents.</Text>
                    </Box>
                    <Box py={2} display={{base: "block", lg: "none"}}>
                        <Flex alignItems="center" color={generalColor.primaryHeading} fontWeight={600} pb={2}>
                            <Icon as={CallIcon}/>
                            <Link href="tel:8002605051" _hover={{ textDecoration: "underline" }}>
                                <Text paddingLeft={2} fontFamily="Poppins">800.260.5051</Text>
                            </Link>
                        </Flex>
                        <Link color={generalColor.primaryText}
                              href="mailto:info@akdealerservices.com" _hover={{textDecoration: "underline"}}>
                            info@akdealerservices.com
                        </Link>
                    </Box>
                    <Box width={"50%"} ml="auto" pt={{base: 2, lg: "unset"}}>
                        <Flex justifyContent={"flex-end"}>
                            <NavBarLinkButton fontSize={14} fontWeight={"md"} text={"Contacts"}
                                              onClick={() => navigate(URLPaths.CONTACTS.link)}
                                              paddingLeft={1.5}/>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}
