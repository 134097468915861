import React, {FC, useRef, useState} from 'react';
import {Box} from "@chakra-ui/react";
import {Product, ProductChangeParent} from "../../shared/entities/Product/Product";
import {ProductTypes} from "../../common/constants";
import {ProductController} from "../../controllers/ProductController";
import {useDispatch} from "react-redux";
import {ActionTypes} from "../../redux/CurrentState/DragAndDropReducer";
import {useAppSelector} from "../../redux/Store";
import {ModalService} from "../../service/Modal/ModalService";

type DragAndDropBoxProps = {
    children?: React.ReactNode;
    onDrop?: (draggedItem: string) => void;
    item: Product;
    isDraggable?: boolean;
    width?: string;
    height?: string;
    orderByZone?: boolean;
    row?: boolean;
};

const DragAndDropBox: FC<DragAndDropBoxProps> = ({
                                                     children,
                                                     onDrop,
                                                     item,
                                                     width,
                                                     height,
                                                     isDraggable = true,
                                                     orderByZone = false,
                                                     row = false
                                                 }) => {
    const dragRef = useRef<HTMLDivElement>(null);
    const [isDraggedOver, setIsDraggedOver] = useState(false);
    const dispatch = useDispatch();
    const productController = new ProductController(dispatch);
    const modalService = new ModalService(dispatch);
    const idDrag = useAppSelector((state) => state.DragAndDropReducer.idDrag);

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        if (dragRef.current) {
            dispatch({type: ActionTypes.SET_ID, payload: item.id});
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        if (isDraggable) {
            e.preventDefault();
            setIsDraggedOver(true);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (dragRef.current && idDrag) {
            if (orderByZone) {
                productController.changeOrderByProduct({
                    id: Number(idDrag),
                    newPlaceProductId: item.id
                }).then((res) => {
                    dispatch({type: ActionTypes.CHANGE_PRODUCT});
                });
            } else {
                if ((ProductTypes.PRODUCT !== item.type && ProductTypes.CUSTOM !== item.type && ProductTypes.REORDER !== item.type)
                    && item.id !== Number(idDrag)) {
                    const productChangeParent: ProductChangeParent = {
                        id: Number(idDrag),
                        parent: item.id
                    };
                    productController.changeParentProduct(productChangeParent).then((res) => {
                        dispatch({type: ActionTypes.CHANGE_PRODUCT, payload: 1});
                    });
                } else {
                    modalService.setModalData({
                        onSubmit: () => modalService.deleteModalData(),
                        isOpen: true,
                        onOpen: () => {
                        },
                        onClose: () => modalService.deleteModalData(),
                        modalTextData: "Drag and drop error. Can only be transferred to OPTIONS, CATEGORY, SUBCATEGORY types.\n" +
                            "Check the correctness of your actions current type: " + item.type +
                            " From id: " + idDrag + " Where id: " + item.id,
                        context: "error"
                    });
                }
            }
            if (onDrop !== undefined) {
                onDrop(idDrag);
            }
        }
        setIsDraggedOver(false);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDraggedOver(false);
    };

    return (
        <Box key={item.id}
             display={row ? "flex" : "unset"}
             draggable={isDraggable}
             w={width || '100%'}
             h={height || '100%'}
             onDragStart={handleDragStart}
             onDragOver={handleDragOver}
             onDrop={handleDrop}
             onDragLeave={handleDragLeave}
             ref={dragRef}

             style={{
                 border: isDraggedOver ? '2px solid gray' : 'transparent',
                 borderRadius: isDraggedOver ? '5px' : 'transparent',
             }}
        >
            {children}
        </Box>
    );
};

export default DragAndDropBox;
