import React from 'react';
import {Flex} from '@chakra-ui/react';
import {Icon} from "@chakra-ui/icons";
import {
    ArrowTurnLeftUp20RegularIcon,
    EditItem, ArchiveBoxArrowDown,
    TrashItem, ArchiveBoxArrowUp
} from "../Inputs/Icons/Icons";
import {Product, ProductChangeParent, ProductSetArchive} from "../../shared/entities/Product/Product";
import {ProductController} from "../../controllers/ProductController";
import {useDispatch} from "react-redux";
import {URLPaths} from "../../config/application/URLPaths";
import {useLocation, useNavigate} from "react-router";
import {ModalService} from "../../service/Modal/ModalService";
import {ActionTypes} from "../../redux/CurrentState/DragAndDropReducer";
import IconWithTooltip from "../Icon/IconWithTooltip";
import {usePrivileges} from "../../hooks/PrivilegesProvider";


interface EditButtonsProps {
    showEdit?: boolean;
    showRemove?: boolean;
    fromArchive?: boolean;
    toArchive?: boolean;
    riseLevelProduct?: boolean;
    ml?: string;
    mt?: string;
    size?: string;
    item: Product;
}

const EditButtons: React.FC<EditButtonsProps> = ({
                                                     showEdit = false,
                                                     showRemove: showRemove = false,
                                                     fromArchive = false,
                                                     toArchive = false,
                                                     riseLevelProduct: riseLevelProduct = false,
                                                     ml = "0px",
                                                     mt = "0px",
                                                     size = "18px",
                                                     item
                                                 }) => {
    const dispatch = useDispatch();
    const {priv} = usePrivileges();
    const location = useLocation()
    const containsArchive = location.pathname.includes("archive");

    const productController = new ProductController(dispatch);
    const navigate = useNavigate();
    const modalService = new ModalService(dispatch);

    const onEditClick = () => {
        navigate(`${URLPaths.PRODUCTS_EDIT.link}${item.id}`)
    };

    const onRemoveClick = () => {
        modalService.setModalData({
            onSubmit: () => {
                modalService.deleteModalData();
                productController.deleteProduct(Number(item.id)).then(() => {
                    dispatch({type: ActionTypes.CHANGE_PRODUCT});
                });
            },
            isOpen: true,
            onOpen: () => {
            },
            onClose: () => {
                modalService.deleteModalData();
            },
            modalTextData: "Instead of deleting, you can archive the product.Are you sure you want to delete?",
            context: "action"
        });
    };

    const handleSetArchiveProduct = (flag: boolean) => {
        const productSetArchive: ProductSetArchive = {
            id: item.id,
            flag: flag
        };

        productController.setArchiveProduct(productSetArchive).then((res) => {
            dispatch({type: ActionTypes.CHANGE_PRODUCT});
        });
    };

    const handleRiseLevelProduct = () => {
        const productChangeParent: ProductChangeParent = {
            id: Number(item.id),
            parent: -1
        };
        productController.changeParentProduct(productChangeParent).then(() => {
            dispatch({type: ActionTypes.CHANGE_PRODUCT});
        });
    };
    return (
        <Flex spacing="15px" ml={ml} mt={mt} key={item.id}>
            {(showEdit || priv?.PRODUCT_EDIT) && (
                <IconWithTooltip icon={EditItem} tooltipMessage={'Edit'} onClick={onEditClick}/>
            )}
            {(showRemove || priv?.PRODUCT_REMOVE) && (
                <IconWithTooltip icon={TrashItem} tooltipMessage={'Remove'} onClick={onRemoveClick}/>
            )}
            {(fromArchive || (containsArchive && priv?.PRODUCT_EDIT)) && (
                <IconWithTooltip icon={ArchiveBoxArrowUp} tooltipMessage={'back from archive'}
                                 onClick={() => handleSetArchiveProduct(false)}/>
            )}
            {(toArchive || (!containsArchive && priv?.PRODUCT_EDIT)) && (
                <IconWithTooltip icon={ArchiveBoxArrowDown} tooltipMessage={'to archive'}
                                 onClick={() => handleSetArchiveProduct(true)}/>
            )}
            {(riseLevelProduct)  && (
                <IconWithTooltip icon={ArrowTurnLeftUp20RegularIcon} tooltipMessage={'Up on Top Level'}
                                 onClick={() => handleRiseLevelProduct()}/>
            )}
        </Flex>
    );
};

export default EditButtons;