import React, { FC, ReactNode } from "react";
import { Text } from "@chakra-ui/react";

export interface CustomLinkProps {
    children: ReactNode;
    onClick: () => void;
    pb?: number;
}

export const CustomLink: FC<CustomLinkProps> = ({ children, onClick, pb = 1 }) => (
    <Text _hover={{ textDecoration: "underline", cursor: "pointer" }} pb={pb} onClick={onClick}>
        {children}
    </Text>
);
