import {extendTheme} from "@chakra-ui/react";
import {globalStyles} from "./styles";
import {font} from "./foundations/fonts";
import {breakpoints, imageSizes} from "./foundations/breakpoints";
import {buttonStyles} from "./components/button";
import {badgeStyles} from "./components/badge";
import {linkStyles} from "./components/link";
import {drawerStyles} from "./components/drawer";
import {CardComponent} from "./additions/card/Card";
import {CardBodyComponent} from "./additions/card/CardBody";
import {CardHeaderComponent} from "./additions/card/CardHeader";
import {MainPanelComponent} from "./additions/layout/MainPanel";
import {PanelContentComponent} from "./additions/layout/PanelContent";
import {PanelContainerComponent} from "./additions/layout/PanelContainer";
// import { mode } from "@chakra-ui/theme-tools";
export const customTheme = extendTheme(
    {breakpoints}, // Breakpoints
    imageSizes,
    globalStyles,
    font, // Global styles

    buttonStyles, // Button styles
    badgeStyles, // Badge styles
    linkStyles, // Link styles
    drawerStyles, // Sidebar variant for Chakra's drawer
    CardComponent, // Card component
    CardBodyComponent, // Card Body component
    CardHeaderComponent, // Card Header component
    MainPanelComponent, // Main Panel component
    PanelContentComponent, // Panel Content component
    PanelContainerComponent // Panel Container component
);
